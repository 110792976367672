<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">План поставок</div>
                <div class="page__desc">Продукты, которые необходимо купить</div>
            </div>
            <div class="page__actions">
                <router-link
                        :to="{ name: 'StoreSuppliesPlanCreate' }">
                    <button type="button" class="btn btn-success">Добавить</button>
                </router-link>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Магазин</th>
                        <th>Продукт</th>
                        <th>Куплено / Заказано</th>
                        <th>Дата ожидания</th>
                        <th>Дата покупки</th>
                        <th></th>
                    </tr>
                    <tr v-for="(supplyPlan, index) in suppliesPlan" v-bind:key="supplyPlan.id">
                        <td>{{ stores[supplyPlan.store_id].address }}</td>
                        <td>{{ products[supplyPlan.product_id].title }}</td>
                        <td>
                            <nobr>
                                <template v-if="supplyPlan.purchased_at !== null"><span class="badge badge-light">{{ supplyPlan.quantity_purchased }} {{ products[supplyPlan.product_id].packaging_type }}</span> / </template>
                                <span class="badge badge-light">{{ supplyPlan.quantity_ordered }} {{ products[supplyPlan.product_id].packaging_type }}</span>
                            </nobr>
                        </td>
                        <td>{{ supplyPlan.expected_at | moment("DD MMM YYYY HH:mm") }}</td>
                        <td>{{ supplyPlan.purchased_at | moment("DD MMM YYYY HH:mm") }}</td>
                        <td>
                            <nobr>
                                <router-link
                                        v-if="supplyPlan.purchased_at === null"
                                        :to="{ name: 'StoreSuppliesPlanPurchase', params: {id: supplyPlan.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-outline-primary"
                                        value="Покупаю"/>
                                <router-link
                                        v-if="supplyPlan.purchased_at !== null"
                                        :to="{ name: 'StoreSuppliesPlanConfirm', params: {id: supplyPlan.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-outline-success"
                                        value="Подтверждаю доставку"/>
                                <router-link
                                        :to="{ name: 'StoreSuppliesPlanUpdate', params: {id: supplyPlan.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-warning"
                                        value="Изменить"/>
                                <input
                                        @click="deleteSupplyPlan(index)"
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        value="Удалить">
                            </nobr>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreSuppliesPlan',
        computed: {
            suppliesPlan() {
                return this.$store.state.suppliesPlan.suppliesPlan.suppliesPlan
            },
            stores() {
                return this.$store.state.suppliesPlan.suppliesPlan.stores
            },
            products() {
                return this.$store.state.suppliesPlan.suppliesPlan.products
            }
        },
        methods: {
            ...mapActions([
                'storeSuppliesPlan',
                'storeSuppliesPlanDelete',
            ]),
            async getSuppliesPlan() {
                await this.storeSuppliesPlan()
            },
            async deleteSupplyPlan(index) {
                if (confirm('Вы уверены, что хотите удалить?')) {
                    let supplyPlan = this.suppliesPlan.splice(index, 1)[0];

                    await this.storeSuppliesPlanDelete({
                        id: supplyPlan.id
                    })
                }
            }
        },
        created() {
            this.getSuppliesPlan();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>